* {
  // font-family: "Circular Std";
}

.main {
  margin-left: 80px;
}





.loginPage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  background: white;

  .bg-login {
    background: #e9f1f0 !important;
    content: "";
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    border-radius: 0px;
    position: absolute;
    transform-origin: bottom;
  }

 

  .login-form {
    transition: box-shadow 0.2s ease-in-out;
    margin-bottom: 0;
    background-color: transparent;
    z-index: 11111;
    box-shadow: 0px 0px 25px 0px rgb(0 0 0 / 10%);
    border-radius: 20px !important;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-width: 440px;
    width: 100%;

    h6 {
      color: #060606;
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    h4 {
      color: #329993 !important;
      font-size: 12px;
    }

    button {
      color: #ffffff;
      background-color: #329993;
      border: 1px solid #329993;
      font-size: 16px;
      margin-top: 10px;
      border-radius: 6px;
      padding: 8px;

      &:hover {
        background-color: #4ea5a0;
        border-color: #4ea5a0;
      }
    }
  }
}

.sidebarPage {
  background: #ffffff;
  box-shadow: none;
  z-index: 1026;
  width: 80px;
  position: fixed;
  height: 100vh;
  border-right: 1px solid #e3e8e7;
  top: 0;
  left: 0;

  .sidebarTop {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    padding: 15px 20px;

    img {
      width: 60px;
      height: 41px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .nav-show {
    ul {
      list-style-type: none;
      padding: 0px;
      margin: 0px;
    }

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 16px;

      .active {
        background: #e9f1f0;
        color: #339993;

        .dash-micon svg {
          stroke: #339993;
          color: #339993;
        }

        .dash-mtext {
          color: #fff;
        }
      }

      a {
        margin: 10px 11px;
        color: #333333;
        font-weight: 700;
        border-radius: 12px;
        margin-left: 5px !important;
        margin-right: 5px !important;
        padding: 8px;
        text-decoration: none;
        font-size: 14px;
        // border: 1px solid #e9f1f0;
        width: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 53px;
        border-radius: 50%;

        &:hover {
          background: #e9f1f0;
          color: #339993;
          // box-shadow: 0 5px 7px -1px rgba(12, 175, 96, 0.3);
        }

        &:hover .dash-micon svg {
          stroke: #339993;
          color: #339993;
        }

        &:hover .dash-mtext {
          color: #fff;
        }

        .dash-micon {
          // margin-right: 15px;
          // border-radius: 12px;
          // height: 35px;
          // width: 35px;
          // display: inline-flex;
          // align-items: center;
          // justify-content: center;
          // color: #333333;
          // background-color: #fff;
          // box-shadow: -3px 4px 23px rgba(0, 0, 0, 0.1);
          // vertical-align: middle;

          svg {
            color: #758a88;
            stroke: #758a88;
            speak: none;
            font-style: normal;
            font-weight: 400;
            width: 20px;
            height: 20px;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
          }
        }

        .dash-mtext {
          color: #333333;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}

.navbar-page {
  height: 80px;
  background-color: #e9f1f0;
  padding: 0px 24px;

  .titleNav {
    display: flex;
    height: 100%;
    align-items: center;

    h6 {
      color: #329993;
      font-size: 23px;
    }
  }

  .adminProfile {
    max-width: 166px;
    min-width: 137px;
    background-color: white;
    display: flex;
    border-radius: 10px;
    align-items: center;
    border: 1px solid #cbcbcb;
    padding: 0px 10px;
    height: 58px;
    justify-content: center;

    img {
      width: 44px;
      height: 44px;
      object-fit: cover;
      margin-right: 13px;
      border-radius: 50%;
    }

    span {
      color: #525b69;
      font-size: 16px;
      text-transform: capitalize;
    }
  }
}

.dashboard {
  padding: 13px 17px;

  .countShow {
    margin-top: 40px;
    margin-left: 30px;

    .countShowBox {
      box-shadow: 0 6px 30px rgba(182, 186, 203, 0.3);
      margin-bottom: 24px;
      padding: 10px;
      background-color: white;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-height: 166px;
      max-width: 350px;
      height: 100%;
      border: 1px solid #e1e1e1;
      border-radius: 13px;

      .iconShow {
        width: 45px;
        height: 45px;
        border-radius: 17.3552px;
        color: #fff;
        margin: 0px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;

        svg {
          font-size: 20px;
          color: #fff;
        }
      }

      .textShowDash {
        margin-left: 6px;

        h6 {
          font-weight: 600;
          line-height: 1.2;
          color: #6c757d !important;
          font-size: 16px;
        }

        h5 {
          font-weight: 600;
          line-height: 1.2;
          color: #060606;
          font-size: 18px;
        }
      }
    }
  }
}

.messagePage {
  overflow: hidden;

  .emojiModel {
    position: absolute !important;
    bottom: 180px;
    left: 16px;

    .closeModel {
      display: flex;
      justify-content: flex-end;
      float: right;
      position: absolute;
      z-index: 999;
      right: -17px;
      color: gray;
      top: -13px;
      background-color: transparent;
      border: none;

      svg {
        width: 26px;
        height: 26px;
      }
    }
  }

  .replayScroll {
    background-color: rgb(128 128 128 / 25%) !important;
    border-radius: 8px;
    z-index: 1111;
  }

  .textHead {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .statusShow {
      margin-top: 4px;
      margin-left: 13px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .onlineButton {
        background-color: #00b207;
        width: 8px;
        border-radius: 50%;
        height: 8px;
      }

      .offlineButton {
        background-color: #db2e2e;
        width: 8px;
        border-radius: 50%;
        height: 8px;
      }

      h5 {
        color: #465352;
        margin: 0px;
        margin-left: 6px;
        font-weight: 500;
        font-size: 13px;
      }
    }
  }

  .moOnline {
    display: none;
  }

  .leftContent {
    .replayMessageBody {
      .replayMessageBody {
        span {
          color: #171c1b;
        }
      }
    }
  }

  .rightContent {
    .replayMessageBody {
      .replayMessageBody {
        span {
          color: white;
        }
      }
    }
  }

  .replayMessageBody {
    padding: 7px 7px !important;

    .replayMessageText {
      cursor: pointer;

      h6 {
        user-select: none;
        padding: 6px 4px;
        min-width: 58px;
        border-radius: 4px;
        opacity: 0.9;
        margin-bottom: 2px;
        font-size: 13px;
        overflow-wrap: break-word !important;
        border-left: 3px solid;
        background-color: #e8f7f7;
      }
    }
  }

  .replayMessage {
    width: 90%;
    height: 100%;
    position: absolute;
    top: -64px;

    .replayMessageShow {
      background-color: #f5f5f5;
      padding: 7px 8px 10px 11px;
      border-radius: 7.5px;
      border-left: 4px solid #007bfc;
      height: auto;
      display: flex;
      align-items: center;
      min-height: 58px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      position: relative;
      overflow: hidden;
      max-height: 58px;

      h6 {
        margin: 0px;
        font-size: 13.2px;
        max-width: 90%;
        text-wrap: wrap;
        overflow-wrap: break-word;
        color: #667781;
      }

      button {
        background-color: transparent;
        border: none;
        position: absolute;
        right: 8px;
        color: #59656b;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .leftContent {
    &:hover .downarrowMessage {
      display: block !important;
    }
  }

  .rightContent {
    &:hover .downarrowMessage {
      display: block !important;
    }
  }

  .messageBodyShow {
    position: relative;

    .downarrowMessage {
      background-color: transparent;
      border: none;
      color: #329993;
      position: absolute;
      // top: -2px;
      // right: 0px;
      top: -3px;
      display: none;
      right: -30px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .downarrowMessageRight {
      background-color: transparent;
      border: none;
      color: #329993;
      position: absolute;
      // top: -2px;
      // right: 0px;
      right: unset !important;
      top: -3px;
      transform: rotate(180deg);
      left: -31px;
      display: none;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .messageConetnt {
    .chatUserShow {
      background-color: #ffffff;
      height: 100%;
      display: flex;
      margin: 10px 0px !important;
      // border: 1px solid #E3E8E7;
      margin-top: 0px !important;
      border-bottom: 0px !important;
      border-top: 0px !important;
      flex-direction: column;
      align-items: center;
      padding: 14px 6px 14px 16px;
      width: 100%;

      .topHeadShowChat {
        padding: 5px;
        width: 100%;

        h6 {
          color: #171c1b;
          font-size: 24px;
          padding-left: 13px;
          font-weight: 700;
          margin-left: 0px;
          margin: 0px;
          width: 100%;
        }

        .searchInput {
          position: relative;
          margin: 20px 0px;

          input {
            border: 1px solid #e3e8e7;
            border-radius: 52px;
            width: 95%;
            padding: 12px 20px 12px 42px;

            &:focus {
              border-color: #4ea5a0;
              box-shadow: 0 0 0 0.2rem #4ea5a040;
            }

            &:focus-visible {
              outline: none;
              border: none;
            }
          }

          svg {
            position: absolute;
            top: 15px;
            left: 15px;
            width: 20px;
            height: 20px;
          }
        }
      }

      .showUser {
        .infinite-scroll-component {
          overflow-x: scroll !important;
        }

        .chatDataHeader {
          position: relative;
        }

        .allChatUserShow {
          position: relative;
          display: flex;
          flex-direction: column;
          height: calc(100vh - 175px);

          overflow: auto;
        }
      }

      .userProfileBox {
        display: flex;
        justify-content: flex-start;
        cursor: pointer;

        // &:hover {
        //     background-color: #0caf60;
        //     color: white !important;
        // }

        // &:hover .profileText h6 {
        //     color: white !important;
        // }

        // &:hover .profileText h5 {
        //     color: white !important;
        // }

        img {
          width: 56px;
          height: 56px;
          object-fit: cover;
          border-radius: 50%;
        }

        .nameImgProfile {
          align-items: center;
          justify-content: center;
          text-transform: capitalize;
          outline: 2px solid #d7d7d7;
          display: inline-flex;
          width: 45px !important;
          height: 45px !important;
          border-radius: 50%;
          background-color: rgb(255, 58, 110);
          color: white;
        }

        .profileText {
          margin-left: 15px;
          display: flex;
          justify-content: space-between;
          width: 100%;

          .ProfileName {
            margin-left: 5px;

            h6 {
              margin-bottom: 0px;
              color: #171c1b;
              text-transform: capitalize;
              font-size: 16px;
              font-weight: 600;
              text-align: start;
              line-height: 20px;
            }

            h5 {
              color: #747474;
              margin: 0px;
              font-weight: 400;
              line-height: 450;
              text-align: start;
              font-size: 12px;
            }
          }
        }
      }

      .grupeProfile {
        border-radius: 12px;
        padding: 14px 16px;

        .profileText {
          margin-left: 10px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .ProfileName {
            width: 80%;
          }

          h6 {
            color: #171c1b;
            font-size: 18px;
            font-weight: 600;
            margin: 0px;

            span {
              font-size: 12px;
            }
          }

          span {
            margin: 0px;
            font-size: 14px;
            color: #465352;
            text-overflow: ellipsis;
            overflow: hidden;
            min-width: 100px;
            max-width: 27vh;
            display: flex;
            align-items: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .timeTextShow {
          h4 {
            font-size: 14px;
            white-space: nowrap;
            color: #758a89;
          }
        }
      }

      .activeChat {
        background-color: #e8f7f7;
        border: none;
        border-left: 3px solid #329993;

        .nameImgProfile {
          outline: 2px solid white;
        }
      }

      .userProfileContent {
        width: 100%;
        margin: 5px 0px;
        border-radius: 16px;

        &:hover {
          background-color: #e8f7f7;
          border-left: 3px solid #329993;

          .nameImgProfile {
            outline: 2px solid white;
          }
        }
      }
    }

    .chatDataShow {
      width: 100%;
      position: relative;
      border-right: 1px solid #e3e8e7;
      border-left: 1px solid #e3e8e7;
      height: 70%;

      .nameImgProfile {
        align-items: center;
        justify-content: center;
        outline: 2px solid white;
        display: inline-flex;
        width: 45px !important;
        height: 45px !important;
        border-radius: 50%;
        background-color: rgb(255, 58, 110);
        color: white;
      }

      .topchatHead {
        background-color: #ffffff;
        padding: 10px 24px 10px 24px;
        border-radius: 25px 25px 0 0;
        width: 100%;
        border-color: #e3e8e7;
        border: 1px 1px 1px;
        display: flex;
        min-height: 76px !important;
        height: 100%;
        align-items: center;

        .chatImgShow {
          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            object-fit: cover;
          }

          h6 {
            color: #171c1b;
            font-size: 18px !important;
            margin: 0px;
            font-weight: 600;
            margin-left: 13px;
            text-transform: capitalize;
          }
        }

        .chatBodyGrupeImg {
          position: relative;
          display: flex;
          align-items: center;

          img {
            &:nth-last-child(1) {
              position: absolute;
              left: 20px;
            }
          }
        }

        .profileGrupeText {
          ul {
            list-style-type: none;
            padding: 0px;
            margin: 0px;

            li {
              margin: 0px;
              color: #ffffff;
              font-size: 16px;
              margin-left: 30px;
              text-transform: capitalize;

              span {
                font-size: 12px;
                margin-left: 2px;
              }
            }
          }
        }
      }

      .chatDataBody {
        position: relative;
        background-color: #e3e8e7;

        .nameImgProfile {
          width: 30px !important;
          height: 30px !important;
          font-size: 12px;
        }

        .allChatShow {
          position: relative;
          display: flex;
          flex-direction: column-reverse;
          height: calc(100vh - 260px);
          overflow: auto;

          &::-webkit-scrollbar-track {
            height: 10px;
          }

          .leftContent {
            width: 100%;
            margin: 6px 10px 6px 3px;

            .leftChat {
              display: flex;
              align-items: flex-end;
              margin: 3px 10px 6px 10px;

              .chatDataProfile {
                margin-right: 6px;

                img {
                  width: 36px;
                  height: 36px;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }

              .messageShow {
                .UserameShow {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  h6 {
                    font-size: 13px;
                    margin-left: 0px !important;
                    color: #758a89;
                    margin: 0px;
                    text-align: left;
                    width: 100%;
                    font-weight: 600;
                  }
                }
              }

              .messageBodyShow {
                padding: 11px 16px;
                min-width: auto;
                max-width: 45vh;
                margin-bottom: 10px;
                border-radius: 8px 8px 16px 0px;
                background-color: #f6f6f6;
                padding-top: 12px;
                padding-right: 30px !important;

                span {
                  color: #171c1b;
                  font-weight: 500;
                  font-size: 14px;
                  overflow-wrap: break-word;
                  line-height: 18px;
                  word-wrap: break-word;
                  text-align: left;
                }
              }
            }
          }

          .rightContent {
            width: 100%;

            .rightChat {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin: 6px 10px 6px 3px;

              .chatDataProfile {
                margin-left: 6px;

                img {
                  width: 36px;
                  height: 36px;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }

              .messageShow {
                .mentionName {
                  color: white;
                  background-color: #7abab5;
                  padding: 4px;
                  font-size: 14px !important;
                  border-radius: 7px;
                  margin-right: 3px;
                }

                .messageBodyShow {
                  padding: 10px 13px;
                  // border-radius: 16px 16px 0px 16px;
                  border-radius: 8px 8px 0px 16px;

                  // min-width: 350px;
                  min-width: auto;
                  max-width: 45vh;
                  margin-bottom: 10px;
                  border-bottom-right-radius: 0;
                  background-color: #329993;

                  span {
                    color: #ffffff;
                    font-weight: 500;
                    font-size: 14px;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    font-size: 13px !important;
                    line-height: 18px;
                    padding: 1px 2px;
                    text-align: left;
                  }
                }

                .UserameShow {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  h6 {
                    font-size: 13px;
                    margin-right: 10px;
                    color: #758a89;
                    margin: 0px;
                    text-align: right;
                    width: 100%;
                    font-weight: 600;
                  }
                }
              }
            }
          }

          .messageShowImg {
            align-items: flex-end !important;

            .messageShow {
              background-color: transparent;

              .messageBodyShow {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 150px;
                  height: 150px;
                  cursor: pointer;
                  object-fit: cover;
                  border-radius: 14px;
                  border: 1px solid #329993;
                  padding: 5px;
                }
              }

              .UserameShow {
                h6 {
                  color: #060606;
                }
              }
            }
          }
        }
      }

      .chatDataFooter {
        background-color: #e3e8e7;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        form {
          position: relative;
          border-radius: 20px;
          background-color: #ffffff;
          width: 90%;
        }

        .chatButtonSend {
          .inputChat__control {
            textarea {
              width: 100% !important;
              border-radius: 13px !important;
              font-size: 14px !important;
              font-weight: 500 !important;
              line-height: 18px !important;
            }
          }
        }

        .socialButton {
          border-top: 1px solid #e3e8e7;
          margin: 25px 20px !important;
          padding-top: 8px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          z-index: 1111;
          position: relative;
          margin-bottom: 13px !important;

          .media-icon {
            svg {
              color: #758a89;
              width: 20px;
              height: 20px;
            }
          }

          .emojiButtonIcon {
            outline: none;
            background-color: transparent;
            border: none;
            margin-left: 10px;

            svg {
              color: #758a89;
              width: 20px;
              height: 20px;
            }
          }

          .buttonImg {
            display: flex;
            align-items: center;
          }

          .sendIconButton {
            button {
              background-color: transparent;
              border: none;
            }

            .sendButtonIcon {
              svg {
                width: 25px;
                color: #329993;
                height: 25px;
              }
            }
          }
        }
      }

      .chatDataFooter {
        position: relative;
        background-color: #e3e8e7;

        .userNameModelShow {
          background-color: rgb(215 215 215 / 88%);
          position: absolute;
          width: 100%;
          height: 91px;
          z-index: 99999;
          border-radius: 10px;
          padding: 10px 0px 0px 0px;
          top: -85px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;

          .userNameShow {
            display: flex;
            align-items: center;
            background-color: #0caf60d6;
            border-radius: 10px;
            margin: 4px !important;
            cursor: pointer;
            padding: 2px 3px;
            padding-bottom: 3px !important;

            &:hover {
              background-color: #0a8c4d;
            }

            img {
              width: 25px;
              height: 25px;
              border-radius: 50%;
            }

            span {
              width: 25px;
              height: 25px;
            }

            h4 {
              font-size: 11px;
              text-transform: capitalize;
              color: white;
              margin-left: 5px !important;
              margin-bottom: 0px;
            }
          }
        }
      }

      .chatButtonSend {
        display: block;

        .inputChat {
          width: 100%;
          height: 60px;
          position: relative !important;
          overflow: unset !important;

          textarea {
            padding: 0px 15px;
            border-radius: 8px;
            outline: none;
            width: 100% !important;
            color: #293240;
            overflow: auto;
            top: 18px !important;
            background-color: #ffffff !important;
            background-clip: padding-box;
            border: none;
            appearance: none;
            transition: border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;
          }
        }

        input {
          padding: 10px 15px;
          border-radius: 25px;
          outline: none;
          width: 100%;
          color: #293240;
          background-color: #ffffff;
          background-clip: padding-box;
          border: 1px solid #ced4da;
          appearance: none;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;

          &:focus {
            border-color: #0caf60;
            box-shadow: 0 0 0 0.2rem rgba(12, 175, 96, 0.25);
          }

          &:focus-visible {
            outline: none;
            border: none;
          }
        }

        .socialButton {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          margin-bottom: 15px !important;

          button {
            outline: none;
            border: none;
          }

          .media-icon {
            background-color: #0caf60;
            width: 48px;
            height: 48px;
            display: flex;
            border-radius: 23px;
            cursor: pointer;
            align-items: center;
            justify-content: center;

            &:hover {
              box-shadow: 0 5px 7px -1px rgba(12, 175, 96, 0.3);
              background-color: #3cc181;
            }

            svg {
              color: white;
              width: 40px;
              height: 25px;
            }
          }

          .sendButtonIcon {
            background-color: #0caf60;
            width: 48px;
            height: 48px;
            margin-left: 5px;
            display: flex;
            border-radius: 23px;
            cursor: pointer;
            align-items: center;
            justify-content: center;

            &:hover {
              box-shadow: 0 5px 7px -1px rgba(12, 175, 96, 0.3);
              background-color: #3cc181;
            }

            svg {
              color: white;
              width: 40px;
              height: 25px;
            }
          }
        }
      }
    }
  }
}

.clientPage {
  padding: 13px 17px;
  margin-right: 17px;

  .clientImg {
    align-items: center;
    justify-content: center;
    outline: 2px solid rgb(207, 203, 203);
    display: inline-flex;
    width: 40px !important;
    font-size: 16px;
    height: 40px !important;
    border-radius: 50%;
    background-color: rgb(255, 58, 110);
    color: white;
  }

  .table-custom {
    margin: 25px 15px 0px 15px;
  }
}

.DeveloperPage {
  padding: 13px 17px;
  margin-right: 17px;

  .table-custom {
    margin: 25px 15px 0px 15px;
  }

  .DeveloperDialog {
    .mainDiaogBox {
      max-width: 61vh !important;
      height: unset !important;
    }
  }
}

.TicketPage {
  padding: 13px 17px;
  margin-right: 17px;

  .TicketDialog {
    .mainDiaogBox {
      max-width: 65vh !important;
      height: unset !important;
    }
  }

  .DeveloperDialog {
    .mainDiaogBox {
      max-width: 60vh;
    }
  }

  .table-custom {
    margin: 25px 15px 0px 15px;
  }

  .ticketBoxInput {
    label {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 8px;
      color: #293240;
    }

    .ticketInputShow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0.575rem 1rem;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: #293240;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      appearance: none;
      border-radius: 6px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
}

.adminPageChat {
  .EmojiPickerReact {
    bottom: 166px !important;
  }

  .leftContent {
    display: flex;

    .messageShowImg {
      display: flex;
      align-items: flex-end;
      margin: 3px 10px 6px 6px;
      flex-direction: row-reverse;

      .messageBodyShow {
        background-color: transparent !important;
      }
    }
  }

  .rightContent {
    .messageShowImg {
      .messageBodyShow {
        background-color: transparent !important;
        padding-bottom: 0px !important;
      }
    }
  }
}

.adminInfo {
  .appInfoHead {
    margin-bottom: 8px !important;
  }

  .appInfoBody {
    border-bottom: unset !important;
    padding-bottom: 0px !important;

    .profileViewOth {
      width: 100%;

      span {
        padding-bottom: 12px !important;
      }

      .showProfileView {
        border-bottom: 1px solid #e3e8e7;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        padding-bottom: 10px !important;
        justify-content: center;
        padding: 10px 0px;
        padding-top: 0px;
        align-items: center;

        .nameImgProfile {
          width: 100px !important;
          height: 100px !important;
        }

        .statusShow {
          margin-top: 4px;
          margin-left: 13px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;

          .onlineButton {
            background-color: #00b207;
            width: 10px;
            border-radius: 50%;
            height: 10px;
          }

          .offlineButton {
            background-color: #db2e2e;
            width: 10px;
            border-radius: 50%;
            height: 10px;
          }

          h5 {
            color: #465352;
            margin: 0px;
            margin-left: 6px;
            font-size: 14px;
            font-weight: 500;
          }
        }

        img {
          width: 100px !important;
          border-width: 2px !important;
          height: 100px !important;
        }

        h6 {
          margin: 0px !important;
          margin-top: 10px !important;
          font-size: 20px !important;
          text-align: center;
        }
      }
    }
  }
}

.chatUserDetails {
  overflow: scroll;
  height: 100vh;
}

.appInfo {
  background-color: #ffffff;
  padding: 20px;

  .appInfoBody {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #e3e8e7;
    align-items: center;
    margin-top: 20px;
    padding-top: 10px;
    justify-content: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #e3e8e7;

    span {
      color: #329993;
      font-weight: 700;
      font-size: 14px;
      padding: 10px;
      max-width: 100px;
      border-radius: 10px;
      margin: 10px 0px;
      width: 100%;
      margin-top: 16px !important;
      text-align: center;
      background-color: #e8f7f7;
      text-transform: capitalize;
    }

    .nameImgProfile {
      align-items: center;
      justify-content: center;
      outline: 2px solid white;
      display: inline-flex;
      width: 116px !important;
      font-size: 26px;
      height: 116px !important;
      border-radius: 50%;
      background-color: rgb(255, 58, 110);
      color: white;
    }

    img {
      width: 136px;
      height: 136px;
      object-fit: cover;
      border-radius: 50%;
      border: 4px solid #329993;
    }

    h6 {
      font-size: 25px;
      color: #465352;
      margin: 0px;
      margin-top: 10px;
      font-weight: 600;
    }
  }

  .appInfoHead {
    margin-top: 20px;
    border-radius: 14px;
    padding: 14px 20px;
    background-color: #329993;

    span {
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
    }

    h5 {
      font-size: 20px;
      color: #465352;
      margin: 0px;
      margin-top: 6px;
      margin-bottom: 4px;
      font-weight: 600;
      color: white;
    }
  }

  .appInfoFooter {
    h6 {
      color: #171c1b;
      font-weight: 700;
      font-size: 20px;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 8px;
    }

    ul {
      list-style-type: none;
      padding-left: 8px;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0px;

        span {
          font-size: 16px;
          color: #465352;
          margin: 0px;
          font-weight: 600;
        }

        h5 {
          color: #329993;
          font-weight: 500;
          margin-top: 3px;
          font-size: 15px;
          margin-left: 10px;
          overflow-wrap: break-word;
          white-space: break-spaces;
          max-width: 50%;
        }
      }
    }
  }
}

.mesageOnPage {
  .emojiModel {
    left: unset !important;
    right: 60px !important;
    bottom: -90px !important;
  }

  .topchatHead {
    height: unset !important;
  }

  .allChatShow {
    height: calc(100vh - 263.55px) !important;
  }

  .chatDataFooter {
    padding-bottom: 25px;
  }

  .rightContent {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .rightChat {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin: 6px 10px 6px 3px;
      flex-direction: row-reverse;
    }

    .messageShowImg {
      flex-direction: unset !important;
      margin: 10px 10px 6px 10px !important;

      .messageBodyShow {
        flex-direction: column;
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        padding-right: 0px !important;
        background-color: unset !important;

        .UserameShow {
          width: 100%;
        }

        img {
          margin-bottom: 15px !important;
          object-fit: cover;
        }
      }
    }
  }

  .leftContent {
    .leftChat {
      display: flex;
      margin: 6px 10px 8px 16px !important;
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: flex-end;

      .messageBodyShow {
        min-width: auto !important;
        max-width: 45vh !important;
      }
    }

    .messageShowImg {
      margin: 10px 10px 6px 10px !important;

      .messageBodyShow {
        flex-direction: column;
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        padding-right: 0px !important;
        background-color: unset !important;
        background-color: transparent !important;

        .UserameShow {
          width: 100%;
        }

        img {
          margin-bottom: 15px !important;
        }
      }
    }
  }
}

.TicketClosedDialog {
  .mainDiaogBox {
    max-width: 45vh !important;

    .dialogBody {
      min-height: 194px;
      display: flex;
      justify-content: center;
      align-items: center;

      h6 {
        font-size: 20px;
        opacity: 0.8;
        margin: 0px;
      }
    }
  }
}

.mainAdminProfile {
  padding: 13px 17px;
  margin-right: 17px;

  .mainTitle {
    margin-bottom: 0px !important;

    .title {
      margin-left: 18px;
    }
  }

  ul {
    li {
      svg {
        color: #329993;
        width: 20px;
        height: 20px;
      }

      span {
        color: #329993;
        cursor: pointer;
        font-weight: 600;
        font-size: 15px;
      }
    }
  }

  .imgUploadProfile {
    svg {
      background-color: rgb(50, 153, 147);
      font-size: 25px;
      cursor: pointer;
      margin-right: 3px;
      display: flex;
      justify-content: center;
      color: white;
      width: 33px;
      height: 33px;
      padding: 6px;
      border-radius: 50%;
    }
  }

  .profile_box {
    color: #329993;
  }

  .submitButton {
    width: 80px;
    height: 40px;
    border-radius: 9.3552px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none;
    margin: 0px 5px;
    border: 1px solid;
    font-size: 20px;
    background-color: rgb(62, 201, 214);
    border-color: rgb(62, 201, 214);

    span {
      font-size: 16px;
    }
  }
}

.notFounPage {
  display: flex;
  height: calc(86vh - 100px);
  align-items: center;
  justify-content: center;
  font-family: "Nunito Sans";
  color: var(--blue);
  font-size: 1em;

  .showPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  button {
    font-family: "Nunito Sans";
  }

  ul {
    list-style-type: none;
    padding-inline-start: 35px;
  }

  svg {
    width: 100%;
  }

  h1 {
    font-size: 40px;
    color: #329993;
    margin: 8px 0px;
    font-weight: 500;
  }

  h2 {
    font-weight: 500;
    color: #329993;
  }
}

.image-viewModel {
  .mainDiaogBox {
    background-color: transparent !important;
    max-width: 94vw !important;
    height: 96vh !important;

    .dialogHead {
      border-bottom: none !important;
      justify-content: flex-end !important;
      padding: 5px !important;
      background-color: transparent !important;
      border-radius: 10px;

      svg {
        color: #329993 !important;
        background-color: white !important;
        opacity: 1 !important;
        width: 30px;
        height: 30px;
      }
    }

    .dialogBody {
      max-height: unset !important;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: 93% !important;
      margin-top: 0px !important;
      padding-top: 0px !important;
      border-radius: 11px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-top: 14px;
        border-radius: 10px;
      }
    }
  }
}

.select-status {
  z-index: 1000;
  min-width: 150px !important;

  & .css-13cymwt-control {
    border-radius: 8px !important;
    border: 1px solid #0a8c4d !important;
    max-width: 166px !important;
  }

  & .css-13cymwt-control:focus {
    border-color: #0a8c4d !important;
    box-shadow: 0 0 0 2px rgba(10, 140, 77, 0.2) !important;
    /* Optional, for a focus ring effect */
  }

  & .css-t3ipsp-control:hover {
    border: 1px solid #0a8c4d !important;
  }

  & .css-t3ipsp-control {
    border: 1px solid #0a8c4d !important;
    box-shadow: none;
    border-radius: 8px !important;
  }
}

.css-1wy0on6,
.css-1hb7zxy-IndicatorsContainer {
  height: 41.5px !important;
  padding: 10px !important;
  font-size: 13px !important;
  color: white !important;
  font-weight: 500 !important;
  border-left: 0px !important;
  border-radius: 5px !important;
  border-top-left-radius: 0px !important;
  background-color: #339993 !important;
  border-bottom-left-radius: 0px !important;
  border: 1px solid #339993 !important;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}
