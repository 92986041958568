::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(28, 43, 32, 0.514);
    cursor: pointer;
    border-radius: 10px;
}

.bg-theme{
    background-color: #4ea5a0;
}

.loader {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2392156863);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    display: flex;
    align-items: center;
    justify-content: center;

    .loaderShow {
        position: absolute;
        top: 45%;
        left: 50%;

        .spinner {
            position: relative;
            width: 56px;
            height: 56px;
            display: grid;
            border: 4.5px solid #0000;
            border-radius: 50%;
            border-color: #339993 #0000;
            animation: spinner-e04l1k 1s infinite linear;

            &::before,
            ::after {
                content: "";
                grid-area: 1/1;
                margin: 2.2px;
                border: inherit;
                border-radius: 50%;
            }
        }
    }
}

.spinner::before {
    border-color: #28a745 #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
}

.spinner::after {
    margin: 8.9px;
}


@keyframes spinner-e04l1k {
    100% {
        transform: rotate(1turn);
    }
}


.custom-input {
    label {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 8px;
        color: #293240;
    }

    input {
        display: block;
        width: 100%;
        padding: 0.575rem 1rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #293240;
        background-color: #ffffff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 6px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
            border-color: #4ea5a0;
            box-shadow: 0 0 0 0.2rem #4ea5a040;
        }

        &:focus-visible {
            outline: none;
            border: none;
        }
    }
}

.textArea {
    label {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 8px;
        color: #293240;
    }

    textarea {
        display: block;
        width: 100%;
        padding: 0.575rem 1rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #293240;
        background-color: #ffffff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 6px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
            border-color: #4ea5a0;
            box-shadow: 0 0 0 0.2rem #4ea5a040;
        }

        &:focus-visible {
            outline: none;
            border: none;
        }
    }
}

.notificationIcon {

    margin-right: 13px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    svg {
        color: rgb(255, 162, 29);
        width: 30px;
        height: 30px;
    }

    span {
        background-color: red;
        width: 20px;
        height: 20px;
        color: white;
        display: flex;
        border-radius: 50%;
        font-size: 13px;
        justify-content: center;
        align-items: center;
        margin-left: -9px;
        margin-top: -7px;
    }
}

.custom-select {
    label {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 8px;
        color: #293240;
    }

    #react-select-3-listbox {
        max-height: 170px !important;
    }

    .select-optionList {
        margin: 8px 10px;
        border-radius: 6px;
        cursor: pointer;
        padding-bottom: 5px !important;
        padding: 2px 3px;

        img {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            margin-right: 10px;
            object-fit: cover;
        }

        &:hover {
            background-color: #ebebeb;
        }

        span {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            color: #293240;
            text-transform: capitalize;
        }
    }

    .optionShow-option {
        img {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            margin-right: 10px;
            object-fit: cover;
        }

        span {
            text-transform: capitalize;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            color: #293240;
        }
    }
}

.backButtonClick {
    display: none;
}



.image-show {
    .imgInput {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;

        label {
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 8px;
            color: #293240;
        }

        input {
            border: 1px solid #ced4da;
            color: #293240;
            width: 100%;
            font-size: 0.875rem;
            font-weight: 400;
            ;
        }

        input[type=file]::file-selector-button {
            border: none;
            cursor: pointer;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            padding: 6px;
            margin-right: 15px !important;
            max-width: 120px;
            width: 100%;
            height: 40px;
            border-radius: .2em;
            color: white;
            background-color: #4ea5a0;
            transition: 1s;
        }

    }

    .imgShow {
        img {
            width: 130px;
            height: 118px;
            object-fit: cover;
            border-radius: 10px;
            margin-top: 15px;
        }
    }
}

.errorMessage {
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 0px;
    color: red !important;
}

.mainTitle {
    margin-bottom: 20px;
    margin-top: 10px;

    .title {
        color: #060606;
        font-weight: 500;
        font-size: 24px;
    }

    .total {
        h6 {
            color: #5D6F6D;
            margin: 0px;
            font-size: 20px;

            span {
                margin: 0px 4px;
                color: #339993;
            }
        }
    }
}


.statusButton {
    span {
        color: white !important;
        font-size: 12px !important;
        padding: 6px 17px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 8px !important;
        max-width: 75px;
    }

    .reopenTicket {
        background-color: rgb(255, 162, 29);
    }

    .openTicket {
        background-color: #28a745;
    }

    .closedTicket {
        background-color: #eb0a0a;
    }

}

.copyText {
    svg {
        color: #339993;
        cursor: pointer;
        font-size: 18px;
        margin-left: 8px;
    }

    button {
        border: none;
        outline: none;
        background-color: transparent;
    }
}

.date-range-box {
    display: flex;
    align-items: center;
    position: relative;

    input {
        border-radius: 8px !important;
        border: 1px solid #0a8c4d !important;
        border-right: 0px !important;
        border-top-right-radius: 0px !important;
        max-width: 166px !important;
        border-bottom-right-radius: 0px !important;
    }

    .right-drp-btn {
        height: 41.5px;
        padding: 10px;
        font-size: 13px;
        color: white;
        font-weight: 500;
        border-left: 0px;
        border-radius: 8px !important;
        border-top-left-radius: 0px !important;
        background-color: #339993;
        border-bottom-left-radius: 0px !important;
        border: 1px solid #339993 !important;
    }
}

.zipfileShow {
    display: flex;
    align-items: center;
    padding: 13px;
    background-color: #E8F7F7;
    color: #329993;
    justify-content: space-between;
    border-radius: 10px;

    h6 {
        font-size: 14px;
        max-width: 75%;
        overflow-wrap: break-word;
        white-space: nowrap;
        margin: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    svg {
        height: 26px;
        width: 26px;
        border-right: 1px solid #329993;
        padding-right: 6px;
        margin-right: 6px;
    }

    button {
        background-color: #329993;
        border-radius: 10px;
        border: none;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        padding: 0px;
        margin-left: 6px;
        justify-content: center;

        &:hover {
            background-color: #53b1ac;
        }

        &:active {
            background-color: #53b1ac;
        }

        svg {
            border-radius: 10px;
            border: none;
            color: white;
            width: 24px;
            padding: 0px;
            margin: 0px;
            height: 24px;
            margin-left: 3px;
            display: flex;
            justify-content: center;
        }
    }
}

.leftContent {
    // svg {
    //     border-left: 1px solid;
    //     padding-left: 6px;
    //     margin-left: 6px;
    //     padding-right: 0px;
    //     margin-right: 0px;
    //     border-right: 0px;
    // }

    .zipfileShow {
        button {
            // margin-left: 0px;
            // margin-right: 3px;

            svg {
                margin: 0px !important;
            }
        }
    }
}

.table-custom {
    box-shadow: 0 6px 30px rgb(117 120 131 / 30%);
    border-radius: 6px;
    transition: box-shadow 0.2s ease-in-out;
    display: block;
    max-height: 600px;
    min-height: 600px;
    background-color: white;
    height: 100%;
    width: 100%;
    overflow: scroll;
    border: none;

    table {
        thead {
            color: #293240;
            background-color: white;

            tr {
                th {
                    padding: 0.9rem 0.75rem;
                    font-size: 16px;
                    color: #060606;
                    text-align: center;
                    white-space: nowrap;
                    opacity: 0.8;
                    background: #f8f9fd;
                }
            }
        }

        tbody {
            .discripton {
                width: 100%;
                text-align: start !important;
                max-width: 250px;
                text-overflow: ellipsis;

                span {
                    white-space: break-spaces !important;
                }
            }

            tr {
                td {
                    padding: 6px 10px;
                    white-space: nowrap;
                    height: 60px;
                    border-top: 2px solid #f1f1f1;
                    border-bottom: 2px solid #f1f1f1;

                    img {
                        border-radius: 50% !important;
                        width: 45px;
                        object-fit: cover;
                        height: 45px;
                    }

                    span {
                        color: #293240;
                        font-size: 14px;
                    }

                    .discripton {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.custom-pagination {
    margin-top: 20px;

    .paginationRow {
        align-items: center !important;

        select {
            color: #000000 !important;
            margin-left: 13px !important;

            .option {
                color: #000000 !important;
            }
        }

    }

    .pagination {
        .page-item {
            a {
                color: white;
                background-color: #339993;
                border-color: #339993;
            }

        }

        .active {
            a {
                background-color: #1f6f6a !important;
            }
        }

        .disabled {
            a {
                opacity: 0.8;
                background-color: #4ea5a0;
                border-color: #4ea5a0;

            }
        }
    }
}

.searchButton {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .inputData {
        position: relative;
        max-width: 250px;
        width: 100%;

        input {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            height: 40px;
            width: 100%;
            padding-left: 6px !important;
            color: #293240;
            padding: 5px;
            background-color: #ffffff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            appearance: none;
            border-radius: 6px;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &:focus {
                border-color: #4ea5a0;
                box-shadow: 0 0 0 0.2rem #4ea5a040;
            }

            &:focus-visible {
                outline: none;
                border: none;
            }
        }

        .searchIcon {
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            background-color: #339993;
            height: 40px;
            display: flex;
            cursor: pointer;
            align-items: center;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            justify-content: center;

            &:hover {
                background-color: #4ea5a0;
                border-color: #4ea5a0;
            }

            svg {
                color: white;
            }
        }
    }
}

.swal2-container {
    z-index: 999999999;
}

.inputChat__suggestions {
    position: absolute !important;
    z-index: 1 !important;
    height: 91px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-radius: 10px;
    background-color: rgb(219 219 219) !important;
    min-width: 360px !important;
    transform: translate(50%, 50%) !important;
    display: flex;
    align-items: center;
    top: unset !important;


    bottom: 20% !important;
    left: 23% !important;
    top: unset !important;
    right: 50% !important;

    ul {
        width: 90%;
    }

    .selectedMention {
        background-color: red !important;
    }

    .userMentionShow {
        display: flex;
        align-items: center;
        background-color: rgb(50 153 147);
        border-radius: 10px;
        margin: 4px !important;
        margin-left: 11px !important;
        cursor: pointer;
        width: 100%;
        padding: 2px 3px;
        padding-bottom: 3px !important;

        &:hover {
            background-color: rgb(50 153 147);
        }

        &:active {
            background-color: rgb(50 153 147);
        }

        &:focus-visible {
            background-color: rgb(50 153 147);
        }

        &:focus {
            background-color: rgb(50 153 147);
        }

        &:active {
            background-color: rgb(50 153 147);
        }


        img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
        }

        span {
            font-size: 11px;
            text-transform: capitalize;
            color: #ffffff;
            margin-left: 5px !important;
            margin-bottom: 0px;
        }

        .nameImgProfile {
            width: 25px !important;
            height: 25px !important;
            font-size: 12px;

        }

    }
}




.cancleButtonAlert {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    margin: 0 0.5rem;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.25;
    border-radius: 0.25rem;
    transition: all 0.15s ease-in-out;

    &:hover {
        color: #fff;
        background-color: #c82333;
        border-color: #bd2130;
    }

    &:active {
        color: #fff !important;
        background-color: #c82333 !important;
        border-color: #bd2130 !important;
    }
}

.swal2-icon-show {
    border: 1px solid rgba(0, 0, 0, 0) !important;
    border-color: #000 !important;
}

.confirmAlert {
    color: #fff;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
    font-weight: normal !important;
    text-align: center !important;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent !important;
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    font-size: 1rem !important;
    line-height: 1.25 !important;
    border-radius: 0.25rem !important;
    transition: all 0.15s ease-in-out !important;

    &:hover {
        color: #fff !important;
        background-color: #218838 !important;
        border-color: #1e7e34 !important;
    }

    &:active {
        color: #fff !important;
        background-color: #218838 !important;
        border-color: #1e7e34 !important;
    }

    &:focus {
        box-shadow: 0 0 0 3px #28a745 !important
    }
}

.submitButtonAlert {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    margin: 0 0.5rem;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.25;
    border-radius: 0.25rem;
    transition: all 0.15s ease-in-out;

    &:hover {
        color: #fff !important;
        background-color: #218838 !important;
        border-color: #1e7e34 !important;
    }

    &:active {
        color: #fff !important;
        background-color: #218838 !important;
        border-color: #1e7e34 !important;
    }
}

.newbuttonone {
    background-color: #339993 !important;
    color: #fff;
    outline: none;
    border-radius: 20px;
    border: none;
    font-size: 12px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}


.newButton {
    background-color: #339993;
    border: 1px solid #339993;
    padding: 5px;
    max-width: 100px;
    height: 38px;
    border-radius: 6px;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    &:hover {
        background-color: #4ea5a0;
        border-color: #4ea5a0;
    }

    svg {
        font-size: 17px;
        margin-right: 3px;
        color: white;
    }
}

.closeTicketMessage {
    background-color: red;
    border-color: red;
    padding: 5px;
    max-width: 100px;
    height: 38px;
    border-radius: 6px;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    &:hover {
        background-color: #e32424;
        border-color: #e32424;
    }

    svg {
        font-size: 17px;
        margin-right: 3px;
        color: white;
    }
}

.closedTicketMessage {
    background-color: #28a745;
    border-color: #28a745;
    padding: 5px;
    max-width: 100px;
    height: 38px;
    border-radius: 6px;
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    &:hover {
        background-color: #3b9e52;
        border-color: #28a745;
    }

    svg {
        font-size: 17px;
        margin-right: 3px;
        color: white;
    }
}

.EmojiPickerReact {
    position: absolute !important;
    bottom: -100px !important;
    left: 20px !important;
}

.action-button {
    button {
        width: 33px;
        height: 33px;
        border-radius: 9.3552px;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        outline: none;
        margin: 0px 5px;
        border: 1px solid;
        font-size: 20px;
    }

    .editButton {
        background-color: rgb(62, 201, 214);
        border-color: rgb(62, 201, 214);
    }

    .deleteButton {
        background-color: #ff3a6e;
        border-color: #ff3a6e;
    }

    .reOpen {
        font-size: 14px;
        background-color: rgb(255, 162, 29);
        border-color: rgb(255, 162, 29);
    }

    .viewButton {
        background-color: rgb(62, 201, 214);
        border-color: rgb(62, 201, 214);

        svg {
            font-size: 30px;
        }
    }
}

.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #0000006b;
    display: inline-flex;
    justify-content: center;
    z-index: 1111;
    align-items: center;
    backdrop-filter: blur(5px);


    & .mainDiaogBox {
        background-color: #fff;
        // max-height: 60vh;
        max-width: 55vw;
        width: 100%;
        // height: 100%;
        // overflow: auto;
        border-radius: 20px !important;


        .dialogHead {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            border-bottom: 1px solid #d8d7d7;

            h6 {
                font-weight: 600;
                opacity: 0.8;
                font-size: 18px;
                margin: 0px;
                cursor: pointer;
                color: #060606;
            }

            svg {
                border: 0;
                font-size: 27px;
                cursor: pointer;
                border-radius: 10px;
                opacity: 0.5;
                color: #000000;

                &:hover {
                    color: #000000;
                    text-decoration: none;
                    opacity: 0.75;
                }
            }
        }

        .dialogBody {
            padding: 16px;
            padding: 16px;
            overflow: auto;
            height: 100%;
            max-height: 450px;

        }

        .dialog-footer {
            padding: 16px;
            border-top: 1px solid #d8d7d7;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            button {
                padding: 5px;
                max-width: 95px;
                width: 100%;
                height: 40px;
                color: white;
                margin: 0px 6px;
                border-radius: 6px;

                span {
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .cancelButton {
                background-color: #d3d4d5;
                border: 1px solid #c6c7c8;

                span {
                    color: #060606;
                    opacity: 0.8;
                    font-weight: 500;
                }

                &:hover {
                    background-color: #d9d9da;
                    border-color: #dbdbdb;
                }
            }

            .submitButton {
                background-color: #339993;
                border: 1px solid #339993;

                &:hover {
                    background-color: #4ea5a0;
                    border-color: #4ea5a0;
                }

            }
        }
    }
}

.showNotification {
    padding: 15px;
    height: 60px;
    position: absolute;
    top: 3%;
    right: 1%;
    width: 300px;
    border-radius: 6px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-weight: 600;
    transition-property: top, left, opacity, transform, background-color;
    transition-duration: 100ms, 500ms, 200ms;
    transition-timing-function: ease;
    display: flex;
    gap: 11px;
    align-items: center;
    opacity: 1;
    z-index: 5;
    background-color: rgb(116 166 163);

    img {
        width: 35px;
        height: 35px;
        border-radius: 6px;
        object-fit: cover;
    }

    .textNotification {
        display: flex;
        align-items: center;

        h6 {
            color: white;
            margin: 0px;
        }
    }

    svg {
        position: absolute;
        right: 11px;
        color: black;
        cursor: pointer;
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 10px;
        padding: 2px;
    }
}

.notfound {
    height: 50vh;
    display: flex;
    color: #329993;
    font-weight: 600;
    align-items: center;
    justify-content: center;
}

.chat-loader {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    border-top: 4px solid grey;
    border-left: 4px solid grey;
    border-right: 4px solid #ff00;
    animation: chatLoader 0.7s infinite linear;
}

@keyframes chatLoader {
    to {
        transform: rotate(360deg);
    }
}