@media screen and (min-device-width: 768px) and (max-device-width: 1050px) {
    .mesageOnPage {
        .appInfo {
            padding: 16px;
        }
    }
}

@media only screen and (max-device-width: 768px) {
    .mainTitle {
        margin-bottom: 18px;
        margin-top: 2px;

        .title {
            font-size: 23px;
        }
    }

    .chatUserDetails {
        overflow: unset;
        height: unset;
    }

    .profileImgGrupe {
        position: relative;
    }

    .moOnline {
        display: block !important;
        position: absolute;
        bottom: -1px;
        right: -2px;

        .onlineButton {
            background-color: #00B207;
            width: 10px;
            border-radius: 50%;
            height: 10px;
        }

        .offlineButton {
            background-color: #db2e2e;
            width: 10px;
            border-radius: 50%;
            height: 10px;
        }
    }

    .table-custom {
        margin: 8px 8px 0px 8px !important;
    }

    .custom-pagination {
        display: flex;
        align-items: center;
    }

    .dashboard {
        .countShow {
            margin: 0px !important;

            .countShowBox {
                height: 90%;
            }
        }
    }

    .searchButtonShow {
        .searchButtonButton {
            justify-content: center !important;
        }

        .searchButton {
            margin-top: 10px;
            justify-content: center !important;
        }
    }

    .DeveloperPage {
        .DeveloperDialog {
            .mainDiaogBox {
                max-width: 56vh !important;
            }
        }
    }

    .backButtonClick {
        display: block !important;
    }

    .TicketPage {
        .anlyticsButton {
            justify-content: space-between;
            margin-bottom: 10px;
        }

        .DeveloperDialog {
            .mainDiaogBox {
                max-width: 56vh !important;
            }
        }
    }

    .sidebarPage {
        display: none;
    }

    #mainPageBgResponsive {
        position: fixed;
        top: 66px;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4196078431);
        display: block;
        justify-content: center;
        z-index: 11;
        align-items: center;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }

    .topHeadShowChat {
        margin-left: 24px;

        h6 {
            margin-left: 20px !important;
        }

        .backButtonClick {
            position: absolute;
            top: 20px;
            left: 12px;

            svg {
                width: 26px;
                color: #9d9d9d;
                height: 26px;
            }
        }
    }

    .sidebarPagemobile {
        position: absolute;
        top: 66px;

        .sidebarTop {
            display: none;
        }
    }

    .navbar-page {
        position: relative;
        height: 70px;

        .menuIconButton {
            display: block !important;
            position: absolute;
            top: 20px;

            svg {
                color: #329993;
                width: 24px;
                height: 24px;
            }
        }

        .titleNav {
            justify-content: flex-end !important;

            h6 {
                font-size: 23px;
                margin: 0px;
            }
        }

        .adminProfile {
            padding: 0px 6px;
            height: 50px;
            border-radius: 8px;
            max-width: 150px;
            min-width: 108px;

            img {
                width: 35px;
                height: 35px;
                margin-right: 5px;
            }

            span {
                font-size: 14px;
            }
        }
    }

    .moPage {
        margin: 0px;
    }

    .chatDataShow {
        display: none;
    }

    .chatUserShow {
        padding: 14px 16px 14px 16px !important;
    }

    .leftSideChat {
        display: block;
    }

    .profileText {
        span {
            min-width: 40px;
            max-width: 40px;
        }
    }

    .adminPageChat {
        .moMessageBody {
            padding: 0px !important;

            .activeChat {
                border-left: 2px solid #329993 !important;
            }

            .userProfileContent {
                &:hover {
                    border-left: 2px solid #329993 !important;
                }

                .userProfileBox {
                    border-radius: 10px;
                    padding: 10px 14px;

                    .profileImgGrupe {
                        img {
                            width: 48px;
                            height: 48px;
                        }
                    }

                    .profileText {
                        .ProfileName {
                            h6 {
                                font-size: 15px;
                            }

                            span {
                                font-size: 13px;

                            }
                        }

                        .timeTextShow {
                            h4 {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }

            .allChatShow {
                height: calc(100vh - 300.55px) !important;


                .nameImgProfile {
                    width: 23px !important;
                    height: 23px !important;
                    font-size: 10px;
                }

                .leftContent {
                    margin: 4px 4px 4px 2px !important;

                    .leftChat {
                        margin: 2px 2px 2px 2px !important;

                        .messageShow {
                            .messageBodyShow {
                                padding: 8px 10px !important;
                                margin-bottom: 6px !important;
                                border-radius: 10px 10px 10px 0px !important;

                                span {
                                    font-size: 12px !important;
                                    line-height: 16px;
                                }

                            }

                            .UserameShow {
                                h6 {
                                    font-size: 10px !important;
                                }
                            }
                        }

                        .chatDataProfile {
                            img {
                                width: 30px !important;
                                height: 30px !important;
                            }
                        }

                    }

                    .messageShowImg {
                        .messageShow {
                            .messageBodyShow {
                                padding: 2px 2px !important;

                                img {
                                    width: 100px !important;
                                    height: 100px !important;
                                    margin-bottom: 6px !important;
                                    border-radius: 8px !important;
                                    border: 1px solid #329993;
                                    padding: 3px !important;
                                }
                            }
                        }
                    }
                }

                .rightContent {
                    margin: 4px 4px 4px 0px !important;

                    .rightChat {
                        margin: 2px 5px 1px 0px !important;

                        .messageShow {
                            .messageBodyShow {
                                padding: 8px 10px !important;
                                margin-bottom: 6px !important;
                                border-radius: 10px 10px 0px 10px;

                                span {
                                    font-size: 12px !important;
                                    line-height: 16px;
                                }

                            }

                            .UserameShow {
                                h6 {
                                    font-size: 10px !important;
                                }
                            }
                        }

                        .chatDataProfile {
                            img {
                                width: 30px !important;
                                height: 30px !important;
                            }
                        }

                    }

                    .messageShowImg {
                        .messageShow {
                            .messageBodyShow {
                                padding: 2px 2px !important;

                                img {
                                    width: 100px;
                                    height: 100px;
                                    margin-bottom: 6px !important;
                                    border-radius: 8px;
                                    border: 1px solid #329993;
                                    padding: 3px;
                                }
                            }
                        }
                    }
                }
            }

            .leftSideChat {
                width: 100%;
                height: 100vh;
            }

            .chatMessageShow {
                width: 100%;
                left: 0;
                overflow: hidden;
                padding: 0px !important;
                position: absolute;
                top: 0;

                .backCharMessage {
                    margin-right: 13px;

                    svg {
                        color: gray;
                        width: 26px;
                        height: 26px;
                    }
                }

                .openDetailModel {
                    position: absolute;
                    right: 16px;
                    display: block !important;

                    button {
                        background-color: transparent;
                        border: none;
                        color: #329993;

                        svg {

                            width: 20px;
                            height: 20px;
                        }
                    }
                }

            }

            .mobileDetailsChat {
                position: absolute;
                padding: 0px;
                right: 10%;
                top: 95px;
                width: 80%;

                .appInfoBg {
                    position: fixed;
                    top: 74px;
                    left: 0;
                    width: 100%;
                    height: 100vh;
                    background-color: rgba(0, 0, 0, 0.4196078431);
                    display: block;
                    justify-content: center;
                    z-index: 1111;
                    align-items: center;
                    -webkit-backdrop-filter: blur(5px);
                    backdrop-filter: blur(5px);

                }

                .appInfo {
                    display: none;
                    border-radius: 10px;
                    position: relative;
                    margin-top: 12px;
                    z-index: 11111;
                    padding: 10px;

                    .appInfoHead {
                        margin-top: 10px;
                        padding: 11px 13px;

                        span {
                            font-size: 14px;
                        }

                        h5 {
                            font-size: 16px;
                            margin: 0px;
                            margin-top: 3px;
                            margin-bottom: 1px;
                            font-weight: 600;
                        }
                    }

                    .appInfoBody {
                        margin-top: 10px;
                        padding-bottom: 3px;

                        img {
                            width: 80px;
                            margin-top: 10px;
                            height: 80px;
                            border: 2px solid #329993;
                        }

                        h6 {
                            font-size: 17px;
                            margin-top: 16px;
                        }

                        span {
                            font-size: 12px;
                            padding: 10px;
                            margin: 10px 0px;
                            margin-top: 6px !important;
                        }

                        .nameImgProfile {
                            font-size: 23px !important;
                        }
                    }

                    .profileViewOth {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .appInfoFooter {
                        h6 {
                            font-size: 16px;
                            padding-top: 8px;
                            padding-bottom: 8px;
                        }

                        ul {
                            li {
                                span {
                                    font-size: 12px;
                                }

                                h5 {
                                    font-size: 12px;
                                    margin: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .inputChat__suggestions {
        min-width: 200px !important;
        left: 0% !important;
        width: 37%;
    }

    .closeTicketMessage {
        font-size: 12px;
        height: 30px;
        margin-top: -8px !important;
        margin-bottom: 8px;
        width: 85px;
    }

    .closeIcon {
        display: block !important;
        position: absolute;
        right: -17px;
        top: -17px;
        cursor: pointer;
        background-color: transparent;
        border: none;

        svg {
            width: 28px;
            height: 28px;
            color: #393636;
        }
    }
}

@media screen and (min-device-width: 600px) and (max-device-width: 768px) {
    .mesageOnPage {
        .appInfo {
            padding: 17px;

            .appInfoHead {
                padding: 10px 16px;

                span {
                    font-size: 14px;
                }

                h5 {
                    font-size: 20px;
                }
            }

            .appInfoBody {
                h6 {
                    font-size: 20px;
                }
            }

            .appInfoFooter {
                h6 {
                    font-size: 20px;
                }

                ul {
                    li {
                        span {
                            font-size: 14px;
                        }

                        h5 {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

}


@media only screen and (max-device-width: 600px) {
    .image-viewModel {
        .mainDiaogBox {
            width: 90% !important;
        }
    }

    .DeveloperPage {
        .DeveloperDialog {
            .mainDiaogBox {
                max-width: 45vh !important;
            }
        }
    }

    .TicketPage {
        .DeveloperDialog {
            .mainDiaogBox {
                max-width: 45vh !important;
            }
        }
    }

    .login-form {
        width: 94% !important;
    }

    .navbar-page {
        .titleNav {
            h6 {
                text-align: center;
                margin-right: 6px;
            }
        }
    }


    .mesageOnPage {
        .messageConetnt {
            .chatDataShow {
                display: block !important;

                .topchatHead {
                    padding: 10px 10px 10px 10px !important;
                    min-height: 66px !important;

                    .chatBodyGrupeImg {
                        margin-left: 10px;

                        span {
                            width: 40px !important;
                            height: 40px !important;
                        }

                        img {
                            width: 40px !important;
                            height: 40px !important;

                        }

                        h6 {
                            font-size: 16px !important;
                            margin-left: 10px;
                        }
                    }
                }

                .chatDataBody {
                    .allChatShow {
                        height: calc(100vh - 300.55px) !important;

                        .nameImgProfile {
                            width: 23px !important;
                            height: 23px !important;
                            font-size: 10px;
                        }

                        .leftContent {
                            margin: 4px 4px 4px 2px !important;

                            .leftChat {
                                margin: 2px 2px 2px 2px !important;

                                .messageShow {
                                    .messageBodyShow {
                                        padding: 5px 8px;
                                        margin-bottom: 6px;
                                        border-radius: 13px 10px 10px 0px;

                                        span {
                                            font-size: 12px !important;
                                            line-height: 16px;
                                        }

                                    }

                                    .UserameShow {
                                        h6 {
                                            font-size: 10px !important;
                                        }
                                    }
                                }

                                .chatDataProfile {
                                    img {
                                        width: 30px !important;
                                        height: 30px !important;
                                    }
                                }

                            }

                            .messageShowImg {
                                .messageShow {
                                    .messageBodyShow {
                                        padding: 2px 2px !important;

                                        img {
                                            width: 100px;
                                            height: 100px;
                                            margin-bottom: 6px !important;
                                            border-radius: 8px;
                                            border: 1px solid #329993;
                                            padding: 3px;
                                        }
                                    }
                                }
                            }
                        }

                        .rightContent {
                            margin: 4px 4px 4px 0px !important;

                            .rightChat {
                                margin: 2px 5px 1px 0px !important;

                                .messageShow {
                                    .messageBodyShow {
                                        padding: 5px 8px;
                                        margin-bottom: 6px;
                                        border-radius: 10px 10px 0px 13px;

                                        span {
                                            font-size: 12px !important;
                                            line-height: 16px;
                                        }

                                    }

                                    .UserameShow {
                                        h6 {
                                            font-size: 10px !important;
                                        }
                                    }
                                }

                                .chatDataProfile {
                                    img {
                                        width: 30px !important;
                                        height: 30px !important;
                                    }
                                }

                            }

                            .messageShowImg {
                                .messageShow {
                                    .messageBodyShow {
                                        padding: 2px 2px !important;

                                        img {
                                            width: 100px;
                                            height: 100px;
                                            margin-bottom: 6px !important;
                                            border-radius: 8px;
                                            border: 1px solid #329993;
                                            padding: 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .chatDataFooter {
                    form {
                        border-radius: 12px;

                        .chatButtonSend {
                            .inputChat {
                                height: 60px;

                                textarea {
                                    font-size: 12px !important;
                                    padding: 0px 10px !important;
                                }
                            }
                        }

                        .socialButton {
                            margin: 23px 18px !important;
                            padding-top: 6px;
                        }
                    }

                }

                .emojiModel {
                    right: 101px !important;
                    bottom: -26px !important;

                    .closeModel {
                        svg {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    .emoji-picker-react {
                        width: 250px !important;
                        height: 250px !important;
                    }
                }
            }

        }


        .mobileDetailsChat {
            position: absolute;
            padding: 0px;
            right: 10%;
            top: 17%;
            width: 80%;

            .appInfoBg {
                position: fixed;
                top: 66px;
                left: 0;
                width: 100%;
                height: 100vh;
                background-color: rgba(0, 0, 0, 0.4196078431);
                display: block;
                justify-content: center;
                z-index: 1111;
                align-items: center;
                -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);

            }

            .appInfo {
                display: none;
                border-radius: 10px;
                position: relative;
                z-index: 11111;
                padding: 10px;

                .appInfoHead {
                    margin-top: 10px;
                    padding: 11px 13px;

                    span {
                        font-size: 14px;
                    }

                    h5 {
                        font-size: 16px;
                        margin: 0px;
                        margin-top: 3px;
                        margin-bottom: 1px;
                        font-weight: 600;
                    }
                }

                .appInfoBody {
                    margin-top: 10px;
                    padding-bottom: 3px;

                    img {
                        width: 80px;
                        height: 80px;
                        border: 2px solid #329993;
                    }

                    h6 {
                        font-size: 17px;
                        margin-top: 16px;
                    }

                    span {
                        font-size: 12px;
                        padding: 6px;
                        margin: 10px 0px;
                        margin-top: 6px !important;
                    }
                }

                .appInfoFooter {
                    h6 {
                        font-size: 16px;
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }

                    ul {
                        li {
                            span {
                                font-size: 12px;
                            }

                            h5 {
                                font-size: 12px;
                                margin: 3px;
                            }
                        }
                    }
                }
            }
        }

        .openDetailModel {
            position: absolute;
            right: 16px;
            display: block !important;

            button {
                background-color: transparent;
                border: none;
                color: #329993;

                svg {

                    width: 20px;
                    height: 20px;
                }
            }
        }

    }

    .inputChat__suggestions__item .userMentionShow {
        span {
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}